body {
  margin: 0;
  font-family: 'Montserrat', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
}
.stickyroll-content {
    position: sticky;
    top: 0;
    height: 100vh;
}

.page {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 15px;
    background: #03A678;
}

.page h1 {
  text-align: center;
  color: white;
}

.page h2 {
  color: #ffd1dc;
  text-align: center;
}

.heart {
  font-size: 60px;
  color: red;
}